import { useEffect } from "react"
import { usePrimaryCommunity } from "../../utils/hooks/community/usePrimaryCommunity"
import { configureColor } from "../../utils/nprogress"

export const ProgressBar = () => {
  const primaryCommunity = usePrimaryCommunity()
  useEffect(()=>{
    if (!primaryCommunity) return configureColor('teal-500')
    console.log(primaryCommunity)
    if (primaryCommunity?.communityObject?.colors.primary){
      configureColor(primaryCommunity.communityObject.colors.primary)
    }
  },[primaryCommunity])
  return null
}
// export default ProgressBar