import '../styles/globals.css'
import '../styles/forceLoadColors.css'
import '../styles/tailwind.scss'
// import 'tailwindcss';
import type { AppProps } from 'next/app'
import { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useRouter } from 'next/router'
import '../utils/nprogress'
import 'nprogress/nprogress.css'
import loadable from '@loadable/component'

import Head from 'next/head'
import { FilesCache, FilesCacheProvider } from '../utils/FilesCacheContext'

import { NotificationManager, NotifierProvider } from '../utils/NotificationsContext'
import { DefaultUserScheduleManager, UserScheduleContextProvider } from '../utils/UserScheduleContext'
import { DisadusAPIWrapperBase } from '../utils/APIs/DisadusAPI/DisadusAPIWrapper'
import { UserGroupIcon } from '@heroicons/react/solid'
import { BiWrench } from 'react-icons/bi'
import { useCurrentUser } from '../utils/hooks/self/useSelfInfo'
import { NotificationsManager } from '../components/Hiddens/Notifications/NotificationManager'
import { QuickSearchMain } from '../components/Hiddens/QuickSearch/QuickSearchMain'
import { useKeybindFufiller } from '../utils/hooks/keybinds/useKeybindFufiller'
import localforage from 'localforage'
import MobileBottomBar from '../components/Sidebar/MobileBottomBar'
import { NavbarLayer } from '../components/Landing/ParallaxLayers/Navbar'
import { ProgressBar } from '../components/Hiddens/ProgressBar'

const Sidebar = loadable(() => import('../components/Sidebar/Sidebar'))
// const ChatSidebar = loadable(() => import('../components/Chat/ChatSidebar/ChatSidebar'))
// const LightScrollbar = loadable(() => import('../components/Scrollbars/LightScrollbar'))
// const DarkScrollbar = loadable(() => import('../components/Scrollbars/DarkScrollbar'))
// const QuickSearch = loadable(() => import('../components/UtilComponents/Quicksearch/QuickSearchMain'))
// const Keybinder = loadable(() => import('../components/UtilComponents/Keybinds'))
// const LatestUpdate = loadable(() => import('../components/LatestUpdate'))
if (globalThis.process) {
  globalThis.process.on('unhandledRejection', (reason, p) => {
    console.log('Unhandled Rejection at: Promise', p)
    // application specific logging, throwing an error, or other logic here
  })
}
DisadusAPIWrapperBase.getInstance()
function MyApp({ Component, pageProps }: AppProps) {
  const [beta, setBeta] = useState(globalThis?.document?.location?.hostname !== 'disadus.app')
  useLayoutEffect(() => {
    localforage.ready()
    return () => {}
  }, [])
  // const [chatData, setChatData] = useState(null as ChatData | null)
  const router = useRouter()
  const user = useCurrentUser()
  // useEffect(() => new ChatContext(setChatData, router).initListeners(), [])
  useKeybindFufiller()

  const [anonymousDark, setAnonymousDark] = useState(true as boolean | null)
  useEffect(() => {
    setAnonymousDark(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
  }, [globalThis?.window])

  useEffect(() => {
    const theme = (user && !!user?.theme) || (!user && anonymousDark) ? 'dark' : 'light'
    document.getElementById('themeContainer')?.classList.remove('light', 'dark')
    document.getElementById('themeContainer')?.classList.add(theme)
  }, [user, anonymousDark])
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
        <link rel="icon" href="/logoold.png" />
      </Head>
      <ProgressBar />

      <div className={``} />
      {/* <ChatContextProvider value={chatData}> */}
      <div
        className={` hidden bg-red-300 text-red-300 bg-yellow-400 text-yellow-400 bg-yellow-300 text-yellow-300 bg-green-300 text-green-300 bg-blue-300 text-blue-300 bg-purple-300 text-purple-300 bg-pink-300 text-pink-300 bg-gray-400 text-gray-400 bg-gray-900 text-gray-900`}
      />
      <FilesCacheProvider value={FilesCache}>
        <NotifierProvider value={NotificationManager}>
          {/* <InvalidLMSCheck /> */}
          <div className={`font-wsans hammerContainer flex flex-row w-full h-full scrollbar-none`} id="themeContainer">
            {beta && (
              <span className="fixed bottom-0 right-0 font-bold text-gray-900/40 dark:text-gray-100/40 font-montserrat z-[99999] m-2 pointer-events-none">
                Disadus Beta Build
              </span>
            )}
            <QuickSearchMain />
            {/* <Keybinder /> */}
            {/* <QuickSearch /> */}
            {/* {(user && user?.theme) || (!user && anonymousDark) ? <DarkScrollbar /> : <LightScrollbar />} */}
            <div
              className={`flex flex-row md:flex-col flex-grow dark:bg-gray-850 bg-gray-300 transition-all duration-200 ease-in-out w-full h-full overflow-auto !scrollbar-none flex-nowrap z-30`}
              id="parentContainer"
            >
              {router.pathname.includes('/app') && !!user && (
                <Sidebar
                // items={[
                //   ...Object.values(ModulesDictionary).splice(0, 3),
                //   {
                //     title: 'Community',
                //     icon: UserGroupIcon,
                //     href: `/app/communities/${user?.primaryCommunity}`,
                //   },
                //   ...Object.values(ModulesDictionary).splice(3),
                //   ...(user?.staffLevel && user?.staffLevel >= 4
                //     ? [
                //         {
                //           title: 'Admin',
                //           icon: BiWrench,
                //           href: `/app/admin`,
                //         },
                //       ]
                //     : []),
                // ]}
                // sidebarOpen={sidebarOpen}
                // setSidebarOpen={setSidebarOpen}
                />
              )}
              {/* {router.pathname.includes('/chat') && !!user && (
                    <ChatSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                  )} */}

              <div
                className={
                  'dark:bg-gray-850 bg-gray-150 dark:text-white flex-grow translate-all transition-all duration-200 ease-in-out'
                }
              >
                <NotificationsManager />
                {/* <LatestUpdate /> */}
                <Component {...pageProps} />
              </div>
              <MobileBottomBar />
            </div>
          </div>
        </NotifierProvider>
      </FilesCacheProvider>
      {/* </ChatContextProvider> */}
    </>
  )
}
export default MyApp
