import { extend } from 'dayjs'
import EventEmitter from 'events'
import { API_DOMAIN } from '../constants'
import { User } from '../types/userTypes'
import { fetcher } from './Fetcher'
import { SocketManager } from './SocketManager'
export class SelfUserClass extends EventEmitter {
  user: User | null = null
  cached: boolean
  private static instance: SelfUserClass
  private constructor() {
    super()
    this.user = globalThis?.localStorage?.getItem('cachedSelf') ? JSON.parse(globalThis?.localStorage?.getItem('cachedSelf') || '') : null
    this.user && this.emit('self-update', this.user)
    this.cached = !!this.user
    this.setMaxListeners(100)
  }
  static getInstance() {
    if (!this.instance) {
      this.instance = new SelfUserClass()
      this.instance.fetch()
    }
    return this.instance
  }
  async listenForSelfUpdates() {
    const update = (event: 'selfUpdate', user: User) => {
      this.user = user
      this.emit('self-update', user)
    }
    SocketManager.getInstance().addListener('selfUpdate', update.bind(this))
  }
  async fetch() {
    const self = SelfUserClass.getInstance()
    if (!globalThis?.localStorage?.getItem('token')) {
      self.user = null
      self.emit('self-update', null)
      self.cached = false
      return
    }
    const userRequest = await fetcher(`${API_DOMAIN}/user/@me`)
    if (userRequest.status === 200) {
      const user = await userRequest.json()
      self.user = user
      self.emit('self-update', user)
      globalThis?.localStorage?.setItem('cachedSelf', JSON.stringify(user))
      self.cached = false
    } else {
      self.user = null
      self.emit('self-update', null)
      self.cached = false
    }
    return this.user
  }
}
