import { useState, useEffect } from 'react'
import { Assignment, Course } from '../../types/courseTypes'
import { useCurrentUser } from '../self/useSelfInfo'
import { useArrayedUserCommunityCourses } from './useArrayedUserCommunityCourses'
import { useUserCourses } from '../course/useUserCourses'
import { CourseMaterialsDataClass } from '../../classes/CourseDataClasses/CourseAssignmentDataClass'

// function getMats(data: CourseMaterialsDataClass, course: Course) {
//   const mats = Array.from(data.materials?.values() || [])
//   return mats.map(mat => [mat, course]) as [Assignment, Course][]
// }

export const useAllMaterials = () => {
  const user = useCurrentUser()
  const [materials, setMaterials] = useState(null as [Course, Assignment][] | null)
  const courses = useArrayedUserCommunityCourses(user?.primaryCommunity)
  useEffect(() => {
    if (!courses || !courses.length) return
    let cancel = [] as (() => void)[]
    const instances = courses.map(course => CourseMaterialsDataClass.getInstance(course.id, true))

    instances.forEach((instance, ind) => {
      const onUpdate = (event: any) => {
        const courseMaterials = instance.materials
        const materials = instances.flatMap(
          (instance, ind) =>
            Array.from(instance.materials?.values() || []).map(x => [courses[ind], x] as [Course, Assignment]) ?? []
        )
        setMaterials(materials)
      }
      instance.on('materialUpdate', onUpdate)
      cancel.push(() => instance.off('materialUpdate', onUpdate))
    })
    setMaterials(
      instances.flatMap(
        (instance, ind) =>
          Array.from(instance.materials?.values() || []).map(x => [courses[ind], x] as [Course, Assignment]) ?? []
      )
    )
    return () => {
      cancel.forEach(c => c())
    }
  }, [courses])
  return materials
}
