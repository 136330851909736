import { useState, useLayoutEffect, useEffect } from 'react'
import { SidebarClass } from '../../classes/SidebarClass'
import { SidebarModuleType } from '../../types/moduleTypes'
import useForceUpdate from '../../useForceUpdate'
import { usePrimaryCommunity } from '../community/usePrimaryCommunity'
import { sidebarDictionary } from '../../data/sidebarDictionary'
const defaultSidebar = [sidebarDictionary.home, sidebarDictionary.courses]
export const useCurrentModules = (cachable: boolean = true) => {
  const [modules, setModules] = useState(defaultSidebar as SidebarModuleType[] | null)
  const communityObject = usePrimaryCommunity()

  useEffect(() => {
    // console.log('Community:', communityObject?.community)
    if (!communityObject?.community) return
    const modulesInfo = SidebarClass.getInstance().sidebar.get(communityObject?.community)
    if (modulesInfo && (cachable || !SidebarClass.getInstance().cached)) {
      setModules([...modulesInfo])
    }
    SidebarClass.getInstance().fetchCommunitySidebar(communityObject?.community)
    const updateSidebar = (sidebar: Map<string, SidebarModuleType[]>) => {
      // console.log('Sidebar:', sidebar, communityObject?.community, cachable || !SidebarClass.getInstance().cached)
      const modulesInfo = sidebar.get(communityObject?.community)
      if (modulesInfo && (cachable || !SidebarClass.getInstance().cached)) {
        // console.log('Sidebar mods:', modulesInfo)
        setModules(mods => {
          // console.log('Sidebar current mods:', mods)
          // console.log('Sidebar new mods:', modulesInfo)
          // console.log('are they equal?', mods === modulesInfo)
          return [...modulesInfo]
        })
      }
    }
    SidebarClass.getInstance().addListener('sidebarUpdate', updateSidebar)
    return () => {
      SidebarClass.getInstance().removeListener('sidebarUpdate', updateSidebar)
    }
  }, [communityObject])
  // console.log('Returning Modules', modules, communityObject?.community)
  return modules
}
