class SessionCache extends Map<string, any> {
  static instance = null as SessionCache | null
  static getInstance() {
    if (!SessionCache.instance) {
      SessionCache.instance = new SessionCache()
    }
    return SessionCache.instance
  }
}
export const SessionCacheManager = SessionCache.getInstance()
