import { useEffect, useState } from 'react'
import { FuzzyMatchAsync } from '../../APIs/SearchAPIs/fuzzylib'
import { SearchParsers } from '../../APIs/SearchAPIs/SearchParsers'
import { SearchMaterial, SearchType } from '../../types/quicksearch'
import { useAllMaterials } from '../community/useAllMaterials'
import { useArrayedUserPrimaryCommunityCourses } from '../community/useArrayedUserPrimaryCommunityCourses'
import { usePrimaryCommunity } from '../community/usePrimaryCommunity'

export const useQuickSearch = (term: string, open: boolean, filterTypes: Set<SearchType>) => {
  const [results, setResults] = useState(null as SearchMaterial[] | null)
  const courses = useArrayedUserPrimaryCommunityCourses()
  const materials = useAllMaterials();
  const community = usePrimaryCommunity()
  useEffect(() => {
    if (!term) return setResults(null)
    const agg = [] as (SearchMaterial | null)[]
    if (!filterTypes.size) {
      filterTypes.add(SearchType.Course)
      filterTypes.add(SearchType.Material)
      filterTypes.add(SearchType.Page)
      filterTypes.add(SearchType.User)

    }
    if (filterTypes.has(SearchType.Course) && courses) agg.push(...courses.map(x => SearchParsers.CourseSearchParser(x, community?.communityObject)))
    if (filterTypes.has(SearchType.Material) && materials) agg.push(...materials.map(x => SearchParsers.AssignmentSearchParser(x[1], x[0])))

    const aggregate = agg.filter(x => x) as SearchMaterial[]
    const aggregateMap = new Map<string, Set<SearchMaterial>>()
    aggregate.filter(x=>x).forEach((material) => {
      const key = material.name
      if (!aggregateMap.has(key)) {
        aggregateMap.set(key, new Set())
      }
      aggregateMap.get(key)!.add(material)
    })
    const asyncMatcher = new FuzzyMatchAsync(term, aggregate.map(x => x.name), (results) => {
      const sortedResults = results.sort((b, a) => a[0] - b[0])
      console.log(sortedResults.map(x=>x[0]),aggregate,courses)
      const parsedResults = sortedResults.slice(0,50)
      .flatMap(x => Array.from(aggregateMap.get(x[1])?.values() || []).map(y => ({ ...y, flaggedName: x[2] }))).filter(x => x) as SearchMaterial[]
      const dedupe = new Set<string>()
      const dedupedResults = parsedResults.filter(x => {
        const key = JSON.stringify(x)
        if (dedupe.has(key)) return false
        dedupe.add(key)
        return true
      })

      setResults(dedupedResults)

    })
    asyncMatcher.start()
    return () => {
      console.log('stopping')
      asyncMatcher.cancel()
    }
  }, [term, open, filterTypes, courses, community])
  return results
}
