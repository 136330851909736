import { useRouter } from 'next/router'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { KeybindManager } from '../../../utils/classes/KeybindsClass'
import { QuickSearchClass } from '../../../utils/classes/QuickSearchClass'
import { useQuickSearch } from '../../../utils/hooks/quicksearch/useQuickSearch'
import { useQuickSearchToggleKeybinds } from '../../../utils/hooks/quicksearch/useQuickSearchToggleKeybinds'
import { SearchType } from '../../../utils/types/quicksearch'
import { QuickSearchResult } from './QuickSearchResult'

export const QuickSearchMain = () => {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(0)
  // Create a filter set with all types enabled
  const filters = useRef(new Set<SearchType>([SearchType.Course,SearchType.Material]))
  const searchBox = useRef<HTMLInputElement>(null)
  const results = useQuickSearch(search, open, filters.current)
  const router = useRouter()
  useQuickSearchToggleKeybinds()
  useLayoutEffect(() => {
    const onOpen = () => {
      setOpen(true)
    }
    const onClose = () => {
      setOpen(false)
    }
    const onToggle = () => {
      setOpen(o => !o)
    }

    QuickSearchClass.getInstance().addListener('open', onOpen)
    QuickSearchClass.getInstance().addListener('close', onClose)
    QuickSearchClass.getInstance().addListener('toggle', onToggle)

    return () => {
      QuickSearchClass.getInstance().removeListener('open', onOpen)
      QuickSearchClass.getInstance().removeListener('close', onClose)
      QuickSearchClass.getInstance().removeListener('toggle', onToggle)
    }
  }, [])
  useEffect(() => {
    const resultUp = {
      keys: ['ArrowUp'],
      name: 'QuickSearch Up',
      description: 'Moves selected result from quick search up',
      callback: () => {
        if (selectedIndex > 0) {
          setSelectedIndex(selectedIndex - 1)
        }
      },
      allowRepeat: true,
    }
    const resultDown = {
      keys: ['ArrowDown'],
      name: 'QuickSearch Down',
      description: 'Moves selected result from quick search down',
      callback: () => {
        if (selectedIndex < (results?.length ?? 0) - 1) {
          setSelectedIndex(selectedIndex + 1)
        }
      },
      allowRepeat: true,
    }
    const resultEnter = {
      keys: ['Enter'],
      name: 'QuickSearch Enter',
      description: 'Selects selected result from quick search',
      callback: () => {
        if (results?.length) {
          const result = results[selectedIndex]
          if (result) {
            router.push(result.href)
            setOpen(false)
          }
        }
      },
    }
    if (!open || !results) return
    KeybindManager.getInstance().addKeybind(resultDown)
    KeybindManager.getInstance().addKeybind(resultUp)
    KeybindManager.getInstance().addKeybind(resultEnter)
    return () => {
      KeybindManager.getInstance().removeKeybind(resultDown)
      KeybindManager.getInstance().removeKeybind(resultUp)
      KeybindManager.getInstance().removeKeybind(resultEnter)
    }
  }, [results, router, selectedIndex,open])
  useEffect(() => {
    setSelectedIndex(0)
  }, [results])
  useLayoutEffect(() => {
    setSearch('')
    open ? searchBox.current?.focus() : searchBox.current?.blur()
  }, [open])
  return (
    <div
      className={`absolute top-0 left-0 w-full h-full dark:bg-gray-900/95 bg-gray-900/40 z-40  ${
        !open && `opacity-0 pointer-events-none`
      }`}
      onClick={() => setOpen(false)}
    >
      <div className={`relative w-full h-full`}>
        <div
          className={`max-w-[90%] w-[65ch] ${
            results?.length ? `max-h-[70%]` : `max-h-[30%]`
          } h-full dark:bg-gray-850 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg overflow-hidden transition-all flex flex-col shadow-md ${
            !open && 'scale-50'
          } duration-75`}
          onClick={e => e.stopPropagation()}
        >
          <div className={`w-full h-20 flex flex-row items-center flex-shrink-0 z-10`}>
            <input
              className={`w-full h-full text-lg px-8 dark:bg-gray-750 bg-gray-50 hover:bg-white focus:bg-white drop-shadow-sm hover:shadow-lg focus:shadow-lg hover:outline hover:outline-gray-100 focus:outline focus:outline-gray-100 dark:hover:outline-gray-800 dark:focus:outline-gray-800 dark:text-gray-200 transition-all`}
              placeholder={`What are you looking for?`}
              value={search}
              onChange={e => setSearch(e.target.value)}
              ref={searchBox}
            />
          </div>
          <div className={`w-full flex-grow relative bg-gray-100 dark:bg-gray-850`}>
            <div className={`absolute top-0 left-0 overflow-auto w-full h-full flex flex-col items-start gap-0.5 p-4`}>
              {!results && (
                <div className={`flex flex-row items-center justify-center w-full h-full dark:text-gray-400`}>
                  Type to search
                </div>
              )}
              {results?.map((result, index) => (
                <QuickSearchResult
                  result={result}
                  key={result.id}
                  selected={index === selectedIndex}
                  onSelect={() => setSelectedIndex(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
