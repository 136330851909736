import { useLayoutEffect, useState } from 'react'
import { UserCommunities } from '../../classes/UserCommunities'
import { DisadusCommunityJoin } from '../../types/communityTypes'
import useForceUpdate from '../../useForceUpdate'

export const useUserCommunities = (cachable: boolean = true) => {
  const [communities, setCommunities] = useState(null as Map<string, DisadusCommunityJoin> | null)
  const forceUpdate = useForceUpdate()
  useLayoutEffect(() => {
    const communityClass = UserCommunities.getInstance()
    const communityInfo = communityClass.communities
    if (communityInfo && (cachable || !communityClass.cached)) {
      setCommunities(communityInfo)
      forceUpdate()
    }
    communityClass.addListener('communitiesUpdate', setCommunities)
    return () => {
      communityClass.removeListener('communitiesUpdate', setCommunities)
    }
  }, [])
  return communities
}
