import { useRouter } from 'next/router'
import { usePrimaryCommunity } from '../../../../utils/hooks/community/usePrimaryCommunity'
import { SidebarModuleType } from '../../../../utils/types/moduleTypes'
import { SidebarKeyview } from '../../Extenals/SidebarKeyview'

export const ModuleListItem = (props: { module: SidebarModuleType; index: number }) => {
  const router = useRouter()
  const community = usePrimaryCommunity()
  const colors = community?.communityObject?.colors
  const { module, index } = props

  const onPage = module.url.toLowerCase() === router.asPath.toLowerCase()
  return (
    <div
      className={`w-full h-10 relative hover:bg-gray-100 dark:hover:bg-gray-700 dark:outline-gray-750 hover:outline outline-gray-200 transition-all rounded-xl cursor-pointer group ${
        onPage && `bg-${colors?.primary}`
      } hover:text-${
        colors?.primary
      } hover:text-opacity-70 bg-opacity-50 flex flex-row justify-start items-center px-2 gap-2 py-2.5 dark:text-gray-300 hover:shadow-md`}
    >
      <div className={`h-full w-[20px]`}>{module.icon}</div>
      <div className={`font-normal text-sm font-wsans flex-grow`}>{module.name}</div>
      {index < 3 && <SidebarKeyview keyName={`${index + 1}`} />}
    </div>
  )
}
