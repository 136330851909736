import { io, Socket } from 'socket.io-client'
import { API_DOMAIN } from '../../constants'
import { getPersistentStore } from '../../PersistentStore'
import { inBrowser } from '../../TetLib'
import { DisadusAPIGetAssignment, DisadusAPIGetAssignmentAsFile } from './DisadusCourseAPITypes'
import CommunitiesListener from './Listeners/CommunityListeners'
import CoursesListener from './Listeners/CoursesListeners'
import { InitializeWebsocketListeners } from './Listeners/Listeners'
export class DisadusAPIWrapperBase {
  socket: Socket | undefined
  ready?: boolean
  identified?: boolean
  static self: DisadusAPIWrapperBase
  constructor() {
    if (!inBrowser()) {
      console.log('Not in browser')
      return
    }
    this.ready = false
    this.socket = io(API_DOMAIN, {
      extraHeaders: {
        Authorization: `${localStorage.getItem('token')}`,
      },
    })

    this.socket!.on('connect', async () => {
      console.log('socket connected')

      this.socket!.once('identified', id => {
        console.log('socket identified as', id)
        this.identified = true
        InitializeWebsocketListeners(this.socket!)
      })
      this.ready = true
    })
    console.log('socket connecting')
    this.socket!.on('disconnect', () => {
      this.ready = false
    })
  }
  async reIdentify(token: string) {
    this.socket!.emit('identify', token)
    await new Promise((resolve, reject) => {
      this.socket!.once('identified', () => {
        resolve(0)
      })
    })
  }
  static getInstance() {
    if (!DisadusAPIWrapperBase.self) {
      DisadusAPIWrapperBase.self = new DisadusAPIWrapperBase()
    }
    return DisadusAPIWrapperBase.self
  }
  async readySocket() {
    this.socket!.connect()
    return new Promise((resolve, reject) => {
      this.socket!.once('connect', () => {
        resolve(true)
      })
    })
  }
  async emitAssignment(courseId: string, assignmentID: string) {
    while (!this.ready) {
      await new Promise(resolve => setTimeout(resolve, 10))
    }
    this.socket!.emit('getAssignment', courseId, assignmentID)
  }
  async listenForAssignment(
    courseId: string,
    assignmentID: string,
    callback: (data: DisadusAPIGetAssignment | DisadusAPIGetAssignmentAsFile) => void
  ) {
    const listenFunction = (data: DisadusAPIGetAssignment | DisadusAPIGetAssignmentAsFile) => {
      callback(data)
    }
    this.emitAssignment(courseId, assignmentID)
    this.socket!.on(`getAssignment`, listenFunction)
    return () => {
      this.socket!.removeListener(`getAssignment`, listenFunction)
    }
  }
}
export const DisadusAPIWrapper = DisadusAPIWrapperBase.getInstance()
