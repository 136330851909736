import { Socket } from 'socket.io-client'
import { DisadusAPIDownloadFile, DisadusAPIGetCommunity, DisadusAPIGetCourse } from '../DisadusCourseAPITypes'

export class FileDownloader {
  static self: FileDownloader
  socket: Socket
  requestsMap: Map<string, (res: DisadusAPIDownloadFile) => void>
  filesCache: Map<string, DisadusAPIDownloadFile>
  constructor(socket: Socket) {
    FileDownloader.self = this
    this.socket = socket
    this.requestsMap = new Map()
    this.filesCache = new Map()
    const onDownload = (data: DisadusAPIDownloadFile) => {
      if (this.requestsMap.has(data.url)) {
        this.requestsMap.get(data.url)!(data)
        this.requestsMap.delete(data.url)
        this.filesCache.set(data.url, data)
      }
    }
    this.socket.on('community.securedownload', onDownload)
  }
  static getInstance() {
    return FileDownloader.self
  }
  downloadFile(url: string, communityID: string) {
    return new Promise(async (res, rej) => {
      if (this.filesCache.has(url)) {
        res(this.filesCache.get(url)!)
      } else if (this.requestsMap.has(url)) {
        while (!this.filesCache.has(url)) {
          await new Promise(r => setTimeout(r, 4))
        }
        return res(this.filesCache.get(url)!)
      }
      this.requestsMap.set(url, res)

      this.socket.emit('community.securedownload', communityID, url)
    }) as Promise<DisadusAPIDownloadFile>
  }
  static async WaitForInstance(): Promise<FileDownloader> {
    return new Promise(async res => {
      while (!FileDownloader.self) {
        await new Promise(r => setTimeout(r, 4))
      }
      res(FileDownloader.self)
    })
  }
}
export default FileDownloader
