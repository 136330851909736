import { ComponentProps } from 'react'

export enum SearchType {
  Material = `material`,
  Course = `course`,
  Page = `page`,
  User = `user`,
}
export type SearchMaterial = {
  id: string
  name: string
  parent?: string
  type: SearchType
  href: string
  icon: string | ((props: ComponentProps<any>) => JSX.Element)
  flaggedName?: string // Where fuzzy is detected
}

