import { Socket } from 'socket.io-client'
import { SessionCacheManager } from '../../../classes/SessionCache'
import { getPersistentStore } from '../../../PersistentStore'
import { Course, CourseGradebook, Assignment, CourseStructure } from '../../../types/courseTypes'
import {
  DisadusAPIGetCourse,
  DisadusAPIGetCourseAnnouncements,
  DisadusAPIGetCourseGrade,
  DisadusAPIGetCourseMaterials,
  DisadusAPIGetCourseStructure,
} from '../DisadusCourseAPITypes'

export class CoursesListener {
  static self: CoursesListener
  socket: Socket
  courseMap: Map<string, Course>
  courseGradesMap: Map<string, CourseGradebook[]>
  courseGradeRequestsMap: Map<string, (grade: CourseGradebook[]) => void>
  courseMaterialsCacheMap: Map<string, Assignment[]>
  courseMaterialsCacheRequestsMap: Map<string, (assignments: Assignment[]) => void>
  courseStructureMap: Map<string, CourseStructure>
  courseStructureRequestsMap: Map<string, (courseStructure: CourseStructure) => void>
  courseRequestsMap: Map<string, (res: Course) => void>
  currentlyProcessing: Set<string>
  ready: boolean = false
  constructor(socket: Socket) {
    CoursesListener.self = this
    this.socket = socket

    this.courseMap = new Map()
    this.courseGradesMap = new Map()
    this.courseGradeRequestsMap = new Map()
    this.courseMaterialsCacheMap = new Map()
    this.courseMaterialsCacheRequestsMap = new Map()
    this.courseStructureMap = new Map()
    this.courseStructureRequestsMap = new Map()
    this.courseRequestsMap = new Map()
    this.currentlyProcessing = new Set()
    this.loadDataFromDisk()
    let courses = {}
    const onCourse = (data: DisadusAPIGetCourse) => {
      if (this.courseRequestsMap.has(data.course?.id || data.courseID)) {
        this.courseRequestsMap.get(data.course?.id || data.courseID)!(data.course)
        console.log('got course', data.course?.id, `as `, data.course?.name)
        this.courseRequestsMap.delete(data.course?.id || data.courseID)
      }
      this.courseMap.set(data?.course?.id, data.course)
      if (data?.course?.id) {
        getPersistentStore.then(store => {
          store.setItem(`cached.course.${data.course.id}`, data.course)
        })
        getPersistentStore.then(store => {
          store.getItem('map.cached.course').then(cachedCoursesMap => {
            let ccm = (cachedCoursesMap || new Set()) as Set<string>
            ccm.add(data.course.id)
            store.setItem('map.cached.course', ccm)
          })
        })
      }
    }
    const onCourseGrade = (data: DisadusAPIGetCourseGrade) => {
      if (this.courseGradeRequestsMap.has(data.course.id)) {
        this.courseGradeRequestsMap.get(data.course.id)!(data.gradebook)
        this.courseGradeRequestsMap.delete(data.course.id)
      }
      this.courseMap.set(data?.course?.id, data.course)
      this.courseGradesMap.set(data?.course?.id, data.gradebook)
      getPersistentStore.then(store => {
        store.setItem(`cached.course.${data.course.id}.gradebook`, data.gradebook)
      })
      getPersistentStore.then(store => {
        store.getItem('map.cached.course').then(cachedCoursesMap => {
          let ccm = (cachedCoursesMap || new Set()) as Set<string>
          ccm.add(data.course.id)
          store.setItem('map.cached.course', ccm)
        })
      })
    }
    const onCourseMaterials = (data: DisadusAPIGetCourseMaterials) => {
      this.currentlyProcessing.delete(`materials.${data.course.id}`)
      if (this.courseMaterialsCacheRequestsMap.has(data.course.id)) {
        this.courseMaterialsCacheRequestsMap.get(data.course.id)!(data.assignments)
        this.courseMaterialsCacheRequestsMap.delete(data.course.id)
      }
      getPersistentStore.then(store => {
        store.setItem(`cached.course.${data.course.id}.materials`, data.assignments)
      })
      getPersistentStore.then(store => {
        store.getItem('map.cached.course').then(cachedCoursesMap => {
          let ccm = (cachedCoursesMap || new Set()) as Set<string>
          ccm.add(data.course.id)
          console.log('adding to map', data.course.id)
          store.setItem('map.cached.course', ccm)
        })
      })
      data.assignments.forEach(assignment => {
        assignment && SessionCacheManager.set(`assignment.${assignment.id}|course.${data.course.id}`, assignment)
      })
      this.courseMaterialsCacheMap.set(data.course.id, data.assignments)
    }
    const onCourseStructure = (data: DisadusAPIGetCourseStructure) => {
      if (this.courseStructureRequestsMap.has(data.course.id)) {
        this.courseStructureRequestsMap.get(data.course.id)!(data.structure)
        this.courseStructureRequestsMap.delete(data.course.id)
      }
      getPersistentStore.then(store => {
        store.setItem(`cached.course.${data.course.id}.structure`, data.structure)
      })
      getPersistentStore.then(store => {
        store.getItem('map.cached.course.structure').then(cachedCoursesMap => {
          let ccm = (cachedCoursesMap || new Set()) as Set<string>
          ccm.add(data.course.id)
          store.setItem('map.cached.course.structure', ccm)
        })
      })
    }
    this.socket.on('getCourse', onCourse)
    this.socket.on('getCourseGrades', onCourseGrade)
    this.socket.on('getAllCourseMaterials', onCourseMaterials)
    this.socket.on('getMaterialsStructure', onCourseStructure)
  }
  static getInstance() {
    return CoursesListener.self
  }
  async loadDataFromDisk() {
    getPersistentStore.then(store => {
      store.getItem('map.cached.course').then(cachedCoursesMap => {
        let ccm = (cachedCoursesMap || new Set()) as Set<string>
        const promises = [] as Promise<any>[]
        ccm.forEach(courseID => {
          promises.push(
            store.getItem(`cached.course.${courseID}`).then(course => {
              course && this.courseMap.set(courseID, course as Course)
            }),
            store.getItem(`cached.course.${courseID}.gradebook`).then(gradebook => {
              gradebook && this.courseGradesMap.set(courseID, gradebook as CourseGradebook[])
            }),
            store.getItem(`cached.course.${courseID}.materials`).then(materials => {
              if (!materials) return
              this.courseMaterialsCacheMap.set(courseID, materials as Assignment[])
              ;(materials as Assignment[])?.forEach(assignment => {
                assignment && SessionCacheManager.set(`assignment.${assignment.id}|course.${courseID}`, assignment)
              })
            }),
            store.getItem(`cached.course.${courseID}.structure`).then(structure => {
              structure && this.courseStructureMap.set(courseID, structure as CourseStructure)
            })
          )
        })
        Promise.all(promises).then(() => {
          this.ready = true
        })
      })
    })
  }
  static async WaitForInstance(): Promise<CoursesListener> {
    return new Promise(async res => {
      while (!CoursesListener.self) {
        await new Promise(r => setTimeout(r, 4))
        console.log('waiting for courses listener')
      }
      console.log('got courses listener')
      res(CoursesListener.self)
    })
  }
  static async getCachedCourse(id: string) {
    return getPersistentStore.then(store => {
      const course = store.getItem(`cached.course.${id}`)
      if (course) return course as unknown as Course
      else return null
    })
  }
  getCourse(id: string, allowCache: boolean = true) {
    if (allowCache && this.courseMap.has(id)) return this.courseMap.get(id)
    this.socket.emit('getCourse', id)
    return new Promise(res => {
      console.log('requesting course', id)
      this.courseRequestsMap.set(id, res)
      setTimeout(() => {
        if (this.courseRequestsMap.has(id)) {
          console.log('no response for course', id)
          this.courseRequestsMap.delete(id)
        }
      }, 5000)
    }) as Promise<Course | undefined>
  }
  getCourseGrade(id: string, allowCache: boolean = true) {
    if (allowCache && this.courseGradesMap.has(id)) return this.courseGradesMap.get(id)
    this.socket.emit('getCourseGrades', id)
    return new Promise(async res => {
      if (this.courseGradeRequestsMap.has(id)) {
        while (!this.courseGradesMap.has(id)) {
          await new Promise(r => setTimeout(r, 4))
        }
        res(this.courseGradesMap.get(id)!)
        return
      }
      this.courseGradeRequestsMap.set(id, res)
    }) as Promise<CourseGradebook[] | undefined>
  }
  getCourseMaterials(id: string, allowCache: boolean = true, deferrable: number = 0) {
    if (allowCache && this.courseMaterialsCacheMap.has(id)) return this.courseMaterialsCacheMap.get(id)

    return new Promise(async res => {
      if (this.courseMaterialsCacheRequestsMap.has(id)) {
        while (!this.courseMaterialsCacheMap.has(id)) {
          await new Promise(r => setTimeout(r, 4))
        }
        res(this.courseMaterialsCacheMap.get(id)!)
      }
      if (deferrable && this.courseMaterialsCacheRequestsMap.size > deferrable) {
        while (this.courseMaterialsCacheRequestsMap.size > deferrable) {
          await new Promise(r => setTimeout(r, 4))
        }
      }
      this.socket.emit('getAllCourseMaterials', id)
      this.courseMaterialsCacheRequestsMap.set(id, res)
    }) as Promise<Assignment[] | undefined>
  }
  getCourseMaterialsSync(id: string, deferrable: number = 0) {
    if (this.courseMaterialsCacheMap.has(id)) return this.courseMaterialsCacheMap.get(id)
    if (!this.courseMaterialsCacheRequestsMap.has(id) && !this.courseStructureMap.has(id))
      this.getCourseMaterials(id, false, deferrable)
  }
  getCourseStructure(id: string, allowCache: boolean = true, deferrable: number = 0) {
    if (allowCache && this.courseStructureMap.has(id)) return this.courseStructureMap.get(id)

    return new Promise(async res => {
      if (this.courseStructureRequestsMap.has(id)) {
        while (!this.courseStructureMap.has(id)) {
          await new Promise(r => setTimeout(r, 4))
        }
        res(this.courseStructureMap.get(id)!)
      }
      if (deferrable && this.courseStructureRequestsMap.size > deferrable) {
        while (this.courseStructureRequestsMap.size > deferrable) {
          await new Promise(r => setTimeout(r, 4))
        }
      }
      this.socket.emit('getMaterialsStructure', id)
      this.courseStructureRequestsMap.set(id, res)
    }) as Promise<CourseStructure | undefined>
  }
  getCourseStructureSync(id: string, deferrable: number = 0) {
    if (this.courseStructureMap.has(id)) return this.courseStructureMap.get(id)
    if (!this.courseStructureRequestsMap.has(id) && !this.courseMaterialsCacheMap.has(id))
      this.getCourseMaterials(id, false, deferrable)
  }
  listenForStructure(id: string, callback: (data: DisadusAPIGetCourseStructure) => void, silent: boolean = false) {
    const listenFunction = (data: DisadusAPIGetCourseStructure) => {
      callback(data)
    }
    !silent && this.socket.emit('getMaterialsStructure', id)
    this.socket!.on(`getMaterialsStructure`, listenFunction)
    return () => {
      this.socket!.removeListener(`getMaterialsStructure`, listenFunction)
    }
  }
  listenForMaterials(id: string, callback: (data: DisadusAPIGetCourseMaterials) => void, silent: boolean = false) {
    const listenFunction = (data: DisadusAPIGetCourseMaterials) => {
      callback(data)
    }
    !silent &&
      !this.currentlyProcessing.has(`materials.${id}`) &&
      this.socket.emit('getAllCourseMaterials', id) &&
      this.currentlyProcessing.add(`materials.${id}`)
    this.socket!.on(`getAllCourseMaterials`, listenFunction)
    return () => {
      this.socket!.removeListener(`getAllCourseMaterials`, listenFunction)
    }
  }
  listenForCourse(id: string, callback: (data: DisadusAPIGetCourse) => void, silent: boolean = false) {
    const listenFunction = (data: DisadusAPIGetCourse) => {
      callback(data)
    }
    !silent && this.socket.emit('getCourse', id)
    this.socket!.on(`getCourse`, listenFunction)
    return () => {
      this.socket!.removeListener(`getCourse`, listenFunction)
    }
  }

  listenForAnnouncements(id: string, callback: (data: DisadusAPIGetCourseAnnouncements) => void) {
    const listenFunction = (data: DisadusAPIGetCourseAnnouncements) => {
      callback(data)
    }
    this.socket.emit('getCourseAnnouncements', id)
    this.socket!.on(`getCourseAnnouncements`, listenFunction)
    return () => {
      this.socket!.removeListener(`getCourseAnnouncements`, listenFunction)
    }
  }
  listenForCourseGrades(id: string, callback: (data: DisadusAPIGetCourseGrade) => void) {
    const listenFunction = (data: DisadusAPIGetCourseGrade) => {
      callback(data)
    }
    this.socket.emit('getCourseGrades', id)
    this.socket!.on(`getCourseGrades`, listenFunction)
    return () => {
      this.socket!.removeListener(`getCourseGrades`, listenFunction)
    }
  }
}
export default CoursesListener
