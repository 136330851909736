import { inBrowser } from './TetLib'
import localforage from 'localforage'
interface LFPolyfill extends Storage {
  ready: () => Promise<void>
}
const storagePfill = {
  getItem: (key: string) => {
    return null
  },
  setItem: (key: string, value: string) => {
    return null
  },
  removeItem: (key: string) => {
    return null
  },
  clear: () => {
    return null
  },
  length: 0,
  key: (index: number) => {
    return null
  },
  ready: () => {
    return Promise.resolve()
  },
} as LFPolyfill
export const getPersistentStore = new Promise(async resolve => {
  try {
    if (!inBrowser()) {
      resolve(storagePfill as any as LocalForage)
      return
    }
    // let loadLF = false
    // await localforage.ready((er: any) => {
    //   console.log('localforage ready', er)
    //   loadLF = true
    // })
    resolve(localforage as LocalForage)
    console.log('persistent store loaded')
  } catch (error) {
    console.trace(error)
    resolve(storagePfill as any as LocalForage)
  }
}) as Promise<LocalForage>
