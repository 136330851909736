import { Socket } from 'socket.io-client'
import { getPersistentStore } from '../../../PersistentStore'
import { User, PublicUser } from '../../../types/userTypes'
import {
  DisadusAPIGetCommunity,
  DisadusAPIGetCourse,
  DisadusAPIGetUser,
  DisadusAPIGetUserByUsername,
  DisadusAPILogin,
} from '../DisadusCourseAPITypes'

export class UsersListener {
  static self: UsersListener
  socket: Socket
  userMap: Map<string, User | PublicUser>
  usernameMap: Map<string, User | PublicUser> = new Map()
  requestsMap: Map<string, (res: User | PublicUser | undefined) => void>
  loginMap: Map<string, (res: DisadusAPILogin) => void>
  userRequests = 0
  constructor(socket: Socket) {
    UsersListener.self = this
    this.socket = socket
    this.userMap = new Map()
    this.usernameMap = new Map()
    this.requestsMap = new Map()
    this.loginMap = new Map()
    this.loadCache()
    const onUser = (data: DisadusAPIGetUser) => {
      if (this.requestsMap.has(data.id)) {
        if (data.user) data.user.pfp = data.user.pfp || '/logo.png'
        this.requestsMap.get(data.id)!(data.user)
        this.requestsMap.delete(data.id)
      }
      this.cacheUser(data.user)
    }
    const onUserFromUsername = (data: DisadusAPIGetUserByUsername) => {
      if (this.requestsMap.has(`uname.${data.username}`)) {
        this.requestsMap.get(`uname.${data.username}`)!(data.user)
        this.requestsMap.delete(`uname.${data.username}`)
      }
      this.cacheUser(data.user)
    }
    this.socket.on('getUserByUsername', onUserFromUsername)
    this.socket.on('getUser', onUser)
    this.socket.on('self', (data: DisadusAPIGetUser) => {
      this.userMap.set('.self', data.user)
      const selfReq = this.requestsMap.get('.self')
      if (selfReq) {
        selfReq(data.user)
      }
      console.log('self', selfReq)
      this.requestsMap.delete('.self')
    })
    this.socket.on('login', (login: DisadusAPILogin) => {
      if (this.loginMap.has('.self')) {
        this.loginMap.get('.self')!(login)
        this.loginMap.delete('.self')
      }
    })
  }
  cacheUser(user?: User | PublicUser) {
    if (user) {
      this.userMap.set(user.id, user)
      this.usernameMap.set(`${user.username}`, user)
      getPersistentStore.then(store => {
        store.setItem('userMap', this.userMap)
        store.setItem('usernameMap', this.usernameMap)
      })
    }
  }
  async loadCache() {
    await (await getPersistentStore).getItem('userMap').then(res => {
      if (res) {
        ;(res as Map<string, User | PublicUser>).forEach((v, k) => {
          this.userMap.set(k, v)
        })
      }
    })
    await (await getPersistentStore).getItem('usernameMap').then(res => {
      if (res) {
        ;(res as Map<string, User | PublicUser>).forEach((v, k) => {
          this.usernameMap.set(k, v)
        })
      }
    })
  }
  static getInstance() {
    return UsersListener.self
  }
  static async WaitForInstance(): Promise<UsersListener> {
    return new Promise(async res => {
      while (!UsersListener.getInstance()) {
        await new Promise(r => setTimeout(r, 4))
      }
      res(UsersListener.getInstance())
    })
  }
  getUser(id: string, allowCache: boolean = true, deferrable: number = 0) {
    if (allowCache && this.userMap.has(id)) return this.userMap.get(id)
    this.userRequests++
    return new Promise(res => {
      if (!this.requestsMap.has(id)) {
        if (deferrable && this.requestsMap.size > deferrable) {
          ;(async () => {
            while (this.requestsMap.size > deferrable) {
              await new Promise(r => setTimeout(r, Math.random() * 500))
            }
            this.socket.emit('getUser', id)
            this.requestsMap.set(id, res)
          })()
          return
        }
        this.socket.emit('getUser', id)
        this.requestsMap.set(id, res)
      } else {
        ;(async () => {
          while (!this.userMap.has(id)) {
            await new Promise(r => setTimeout(r, 4))
          }
          res(this.userMap.get(id))
        })()
      }
    }) as Promise<PublicUser | User | undefined>
  }
  getUserByUsername(username: string, allowCache: boolean = true) {
    if (allowCache && this.usernameMap.has(`uname.${username}`)) return this.usernameMap.get(`uname.${username}`)
    this.socket.emit('getUserByUsername', username)
    return new Promise(res => {
      this.requestsMap.set(`uname.${username}`, res)
    }) as Promise<PublicUser | User | undefined>
  }
  getSelf(allowCache: boolean = true) {
    this.socket.emit('getSelf')
    return new Promise(res1 => {
      if (this.requestsMap.get('.self') && allowCache) {
        ;(async () => {
          while (!this.userMap.has('.self')) {
            await new Promise(r => setTimeout(r, 4))
            console.log('waiting for self')
          }
          res1(this.userMap.get('.self') as User)
        })()
      }
      this.requestsMap.set('.self', res1 as (res: User | PublicUser | undefined) => void)
    }) as Promise<User | undefined>
  }
  login(email: string, password: string) {
    this.socket.emit('login', email, password)
    return new Promise(res => {
      this.loginMap.set('.self', res as (res: DisadusAPILogin) => void)
    }) as Promise<DisadusAPILogin>
  }
}
export default UsersListener
