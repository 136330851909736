import React, { useContext, useEffect, useRef, useState } from 'react'
import { Notif, NotifierContext } from '../../../utils/NotificationsContext'
import useForceUpdate from '../../../utils/useForceUpdate'
import NotificationViewer from './NotificationViewer'

export const NotificationsManager = () => {
  const notificationRef = useRef([] as { notif: Notif; key: string; rendering: boolean }[])
  const notificationBacklogRef = useRef([] as Notif[])
  const forceUpdate = useForceUpdate()
  const Notifier = useContext(NotifierContext)
  const addNotificationToQueue = (notif: Notif) => {
    const key = `${(Math.random() * 10000000).toString(36).substring(0, 4)}${Date.now().toString(36)}`
    const obj = { notif, key, rendering: false }
    notificationRef.current.push(obj)
    forceUpdate()
    ;(async () => {
      while (!obj.rendering) {
        await new Promise(resolve => setTimeout(resolve, 8))
      }
      setTimeout(() => {
        notificationRef.current = notificationRef.current.filter(n => n.key !== key)
        forceUpdate()
      }, (notif.duration || Math.max((notif.message.length + notif.title.length) * 200, 5000)) + 600)
    })()
  }
  useEffect(() => {
    let stop = Notifier.onNotify(notification => {
      addNotificationToQueue(notification)
    })
    return () => Notifier.removeListener(stop)
  }, [])
  return (
    <div className={`fixed bottom-0 right-0 flex flex-col m-8 gap-4 z-50`}>
      {/* render first 5 */}
      {notificationRef.current.slice(0, 5).map(notification => {
        notification.rendering = true
        return (
          <NotificationViewer
            notification={notification.notif}
            dismiss={() => {
              notificationRef.current = notificationRef.current.filter(n => n.key !== notification.key)
            }}
            key={notification.key}
          />
        )
      })}
    </div>
  )
}
