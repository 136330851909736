import EventEmitter from 'events'
import { io, Socket } from 'socket.io-client'
import { API_DOMAIN } from '../constants'

export class SocketManager extends EventEmitter {
  _socket: Socket
  ready: boolean = false
  private static instance: SocketManager
  private constructor() {
    super()
    this._socket = io(API_DOMAIN, {
      extraHeaders: {
        token: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    this._socket.on('connect', () => {
      this.ready = true
    })
    this._socket.on('disconnect', () => {
      this.ready = false
    })
    this._socket.onAny(this.emit.bind(this))
  }
  static getInstance() {
    if (!this.instance) {
      this.instance = new SocketManager()
    }
    return this.instance
  }
  disconnect() {
    this._socket.disconnect()
  }
  get socket() {
    return this._socket
  }
}
