import { extend } from 'dayjs'
import EventEmitter from 'events'
import localforage from 'localforage'
import { API_DOMAIN } from '../constants'
import { getPersistentStore } from '../PersistentStore'
import { DisadusCommunityJoin } from '../types/communityTypes'
import { User } from '../types/userTypes'
import { fetcher } from './Fetcher'
import { SelfUserClass } from './SelfUserClass'

export class UserCommunities extends EventEmitter {
  user: User | null = null
  communities: Map<string, DisadusCommunityJoin> = new Map()
  cached: boolean
  private static instance: UserCommunities
  private constructor() {
    super()
    this.cached = true
    this.loadCache()
    this.linkSelf().then(x => this.fetch())
    this.setMaxListeners(0)
  }
  static getInstance() {
    if (!this.instance) {
      this.instance = new UserCommunities()
    }
    return this.instance
  }
  async linkSelf() {
    const self = SelfUserClass.getInstance()
    this.user = self.user
    const update = (user: User) => {
      this.user = user
      this.fetch()
    }
    self.addListener('self-update', update.bind(this))
  }
  async loadCache() {
    const communities = await (await getPersistentStore).getItem('cachedCommunities')
    if (communities) {
      this.communities = communities as Map<string, DisadusCommunityJoin>
    }
    this.emit('communitiesUpdate', this.communities)
  }

  async fetch() {
    if (!globalThis.localStorage?.getItem('token')) return
    const communitiesRequest = await fetcher(`${API_DOMAIN}/user/@me/communities`)
    if (communitiesRequest.status === 200) {
      const communities = (await communitiesRequest.json()) as DisadusCommunityJoin[]
      this.communities.clear()
      communities.map(community => {
        this.communities.set(community.community, community)
      })
      this.emit('communitiesUpdate', this.communities)
      localforage.setItem('cachedCommunities', this.communities)
      this.cached = false
    } else {
      this.user = null
      this.emit('communitiesUpdate', null)
      this.cached = false
    }
  }
}
