import { fetcher } from './Fetcher'

export class AsyncFetchCacher {
  static instance: AsyncFetchCacher
  static getInstance() {
    if (!this.instance) {
      this.instance = new AsyncFetchCacher()
    }
    return this.instance
  }
  fetchQueue: (() => void)[] = []

  private constructor() {
    // super()
    this.runQueue()
  }

  queueFetch(input: RequestInfo, init?: RequestInit | undefined, withAuth: boolean = true) {
    const newProm = new Promise((resolve, reject) => {
      this.fetchQueue.push(() =>
        fetcher(input, init, withAuth)
          .then(res => resolve(res))
          .catch(er => {})
      )
    }) as Promise<Response>

    return newProm
  }
  async runQueue() {
    while (true) {
      while (this.fetchQueue.length > 0) {
        const fetch = this.fetchQueue.shift()
        if (fetch) {
          await fetch()
        }
      }
      await new Promise(resolve => setTimeout(resolve, 20))
    }
  }
}
