import { Socket } from 'socket.io-client'
import { Community } from '../../../types/communityTypes'
import { DisadusAPIGetCommunity, DisadusAPIGetCourse } from '../DisadusCourseAPITypes'

export class CommunitiesListener {
  static self: CommunitiesListener
  socket: Socket
  courseMap: Map<string, Community>
  requestsMap: Map<string, (res: Community) => void>
  constructor(socket: Socket) {
    CommunitiesListener.self = this
    this.socket = socket
    this.courseMap = new Map()
    this.requestsMap = new Map()
    const onCommunity = (data: DisadusAPIGetCommunity) => {
      if (this.requestsMap.has(data.community?.id)) {
        this.requestsMap.get(data.community.id)!(data.community)
        this.requestsMap.delete(data.community.id)
      }
      this.courseMap.set(data.community?.id, data.community)
    }
    this.socket.on('getCommunity', onCommunity)
  }
  static getInstance() {
    return CommunitiesListener.self
  }
  getCommunity(id: string, allowCache: boolean = true) {
    if (allowCache && this.courseMap.has(id)) return this.courseMap.get(id)
    this.socket.emit('getCommunity', id)
    return new Promise(res => {
      this.requestsMap.set(id, res)
    }) as Promise<Community | undefined>
  }
  static async WaitForInstance(): Promise<CommunitiesListener> {
    return new Promise(async res => {
      while (!CommunitiesListener.self) {
        await new Promise(r => setTimeout(r, 4))
        console.log('waiting for community listener')
      }
      console.log('got community listener')
      res(CommunitiesListener.self)
    })
  }
}
export default CommunitiesListener
