import { Transition } from '@headlessui/react'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline'
import { useEffect, useState } from 'react'
import { Notif } from '../../../utils/NotificationsContext'
import loadable from '@loadable/component'
// const MarkdownRenderer = loadable(() => import('../MarkdownRenderer'))

export const NotificationViewer = (props: { notification: Notif; dismiss: () => void }) => {
  const { notification, dismiss } = props
  const [duration, setDuration] = useState(
    notification.duration || Math.max((notification.message.length + notification.title.length) * 200, 5000)
  )
  const [isVisible, setIsVisible] = useState(false)
  const [countdownStarted, setCountdownStarted] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      console.log(`Rendering ${notification.title}`)
      setIsVisible(true)
    }, 50)
    setTimeout(() => {
      setCountdownStarted(true)
    }, 100)
    setTimeout(() => {
      console.log(`Un-rendering ${notification.title}`)
      setIsVisible(false)
    }, duration + 100)
  }, [notification])
  const Icon = {
    success: CheckCircleIcon,
    error: ExclamationCircleIcon,
    warning: ExclamationIcon,
    info: InformationCircleIcon,
  }[notification.type]
  const iconColor = {
    success: 'green',
    error: 'red',
    warning: 'yellow',
    info: 'blue',
  }[notification.type]

  return (
    <Transition
      show={isVisible}
      enter="transition ease-out transform duration-300 delay-150"
      enterFrom="opacity-0 translate-x-full"
      enterTo="opacity-100"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0 translate-x-full"
    >
      <div
        className={`flex flex-col inf:w-128 2xl:w-112 xl:w-96 lg:w-full rounded-lg overflow-hidden cursor-pointer z-50 shadow-md lg:p-2 lg:px-4 dark:bg-neutral-700 dark:drop-shadow-md  bg-white`}
        onClick={() => {
          setIsVisible(false)
          notification.onClick && notification.onClick()
          setTimeout(() => {
            dismiss()
          }, 300)
        }}
      >
        <div
          className={`text-red-500 bg-red-500 text-green-500 bg-green-500 text-yellow-500 bg-yellow-500 text-blue-500 bg-blue-500 overflow-none hidden`}
        />
        <div
          className={`flex flex-row flex-grow-0 inf:p-4 xl:p-3 lg:p-2 md:p-2 sm:p-1 w-full items-center inf:gap-4 lg:gap-2  flex wrap`}
        >
          {notification.image ? (
            <img src={notification.image} className="w-8 h-8" />
          ) : (
            <Icon className={`w-10 h-10 flex-shrink-0 text-${iconColor}-500 stroke-current`} />
          )}
          <div className={`flex flex-col flex-grow flex-shrink-0`}>
            <span className={`text-lg w-full whitespace-normal lg:text-base`}>{notification.title}</span>
            <div className={`text-sm table table-fixed w-full lg:text-xs overflow-hidden`}>
              {/* <MarkdownRenderer> */}
                {notification.message}
                {/* </MarkdownRenderer> */}
            </div>
          </div>
        </div>
        <div className={`w-full lg:py-2 bg-transparent `}>
          <div className={`w-full bg-gray-200 dark:bg-neutral-800 overflow-hidden rounded-full`}>
            <div
              style={{ transitionDuration: `${duration}ms` }}
              // className={`${countdownStarted ? `w-0` : `w-full`} h-1 bg-${iconColor}-500 transition-all ease-linear`}
            ></div>
          </div>
        </div>
      </div>
    </Transition>
  )
}
export default NotificationViewer
