import { Dispatch, SetStateAction } from 'react'

/**
 * Returns a tailwind color class given a color string (red,orange,yellow,etc).
 */
export const pastelifyColor = (color: string): string => {
  switch (color) {
    case 'red':
      return '-red-300'
    case 'orange':
      return '-yellow-400'
    case 'yellow':
      return '-yellow-300'
    case 'green':
      return '-green-300'
    case 'blue':
      return '-blue-300'
    case 'purple':
      return '-purple-300'
    case 'pink':
      return '-pink-300'
    case 'gray':
      return '-gray-400'
    case 'black':
      return '-gray-500'
  }
  return '-gray-300'
}
export const inBrowser = (): boolean => {
  return typeof window !== 'undefined'
}
export function getCurrentState<S>(setterFunction: Dispatch<SetStateAction<S>>): S {
  let current = undefined as S | undefined
  setterFunction(curr => {
    current = curr
    return curr
  })
  return current!
}
export const letterGrade = (grade: number) => {
  if (grade === -1) return '-'
  grade *= 100
  if (grade >= 93) return grade >= 97 ? 'A+' : 'A'
  if (grade >= 90) return grade >= 93 ? 'A-' : 'A'
  if (grade >= 87) return grade >= 90 ? 'B+' : 'B'
  if (grade >= 83) return grade >= 87 ? 'B-' : 'B'
  if (grade >= 80) return grade >= 83 ? 'C+' : 'C'
  if (grade >= 77) return grade >= 80 ? 'C-' : 'C'
  if (grade >= 73) return grade >= 77 ? 'D+' : 'D'
  if (grade >= 70) return grade >= 73 ? 'D-' : 'D'
  return grade >= 70 ? 'F' : 'F'
}