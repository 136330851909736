import EventEmitter from 'events'
import localforage from 'localforage'
import { io, Socket } from 'socket.io-client'
import { API_DOMAIN } from '../constants'
import { sidebarDictionary } from '../data/sidebarDictionary'
import FetchWrapper from '../FetchWrapper'
import { getPersistentStore } from '../PersistentStore'
import { DisadusCommunityJoin } from '../types/communityTypes'
import { SidebarModuleType, SidebarPreferenceResponse } from '../types/moduleTypes'
import { User } from '../types/userTypes'
import { fetcher } from './Fetcher'
import { ModuleClass } from './ModuleClass'
import { SelfUserClass } from './SelfUserClass'
import { UserCommunities } from './UserCommunities'

/*

Fetch user module preferences and 

{"_id":{"$oid":"624524a3388c8ea1404ed148"},"user":"TET00","community":"gunnSandbox","sidebar":["@core.home","@core.community","@core.courses, "plugin.calculator"]}

*/

export class SidebarClass extends EventEmitter {
  user: User | null = null
  communities: Map<string, DisadusCommunityJoin> = new Map()
  sidebar: Map<string, SidebarModuleType[]> = new Map()
  cached: boolean
  static instance: SidebarClass
  static getInstance() {
    if (!this.instance) {
      this.instance = new SidebarClass()
    }
    return this.instance
  }
  private constructor() {
    super()
    this.cached = true
    // this.linkCommunities()
    this.loadCache()
    this.init()
  }
  async subscribe() {
    const communities = UserCommunities.getInstance()
    const updateCommunity = (communities: Map<string, DisadusCommunityJoin>) => {
      this.communities = communities
      // console.log('sidebar communities updated')
      this.fetch()
    }
    communities.addListener('communitiesUpdate', updateCommunity)
  }
  async init() {
    const selfUser = SelfUserClass.getInstance()
    this.user = selfUser.user || ((await selfUser.fetch()) ?? null)
    this.subscribe()
    this.fetch()
  }
  // async linkCommunities() {
  //   const communities = UserCommunities.getInstance()
  //   this.user = communities.user
  //   this.communities = communities.communities
  //   const updateCommunity = (communities: Map<string, DisadusCommunityJoin>) => {
  //     this.communities = communities
  //     console.log('sidebar communities updated')
  //     this.fetch()
  //   }
  //   const updateUser = (user: User) => {
  //     this.user = user
  //     console.log('sidebar user updated')
  //     user.primaryCommunity && this.fetchCommunitySidebar(user.primaryCommunity)
  //   }

  //   communities.communities.size
  //     ? updateCommunity(communities.communities)
  //     : communities.addListener('communitiesUpdate', updateCommunity)
  //   SelfUserClass.getInstance().user && updateUser(SelfUserClass.getInstance().user!)
  //   SelfUserClass.getInstance().addListener('userUpdate', updateUser)
  // }
  async loadCache() {
    const sidebar = await localforage.getItem('cachedSidebar')
    // console.log('sidebar cache loaded', sidebar)
    if (sidebar) {
      // rehydrate the sidebar
      const sidebarMap = sidebar as Map<string, (SidebarModuleType | string)[]>

      Array.from(sidebarMap.keys()).forEach(async key => {
        this.sidebar.set(
          key,
          ((await Promise.all(
            sidebarMap.get(key)?.map(async mod => {
              if (typeof mod === 'string') {
                return await ModuleClass.getInstance().resolveModule(mod)
              } else {
                return mod
              }
            })!
          )) as SidebarModuleType[]) || []
        )
      })
      // console.log('sidebar cache mapped', sidebarMap)
      // this.sidebar = sidebarMap as Map<string, SidebarModuleType[]>
      // console.log('sidebar cache set', this.sidebar)
      this.emit('sidebarUpdate', this.sidebar)
      // console.log('sidebar cache emitted')
    }
  }

  async fetch() {
    if (!localStorage.getItem('token')) return
    // console.log('fetching sidebar', Array.from(this.communities.keys()))
    const primaryCommunity = this.communities.get(this.user?.primaryCommunity || '')
    this.fetchCommunitySidebar(primaryCommunity?.community || '')
    await Promise.all(
      Array.from(this.communities.keys())
        .filter(x => x !== primaryCommunity?.community)
        .map(community => this.fetchCommunitySidebar(community))
    )
    this.cached = false
    this.emit('sidebarUpdate', this.sidebar)
    // console.log('sidebars fetched', Array.from(this.sidebar.entries()))
  }
  async fetchCommunitySidebar(community: string) {
    if (!community) {
      return
    }
    const sidebarRequest = await fetcher(`${API_DOMAIN}/community/${community}/preferences/sidebar/@me`)
    if (sidebarRequest.status === 200) {
      const sidebarItems = (await sidebarRequest.json()) as SidebarPreferenceResponse

      if (!sidebarItems) {
        //TODO Polyfill default values
        console.warn('no sidebar items found for community', community)
        return
      }
      const moduleClass = ModuleClass.getInstance()
      const sidebar = (await (
        await Promise.all(sidebarItems.sidebar.map(item => moduleClass.resolveModule(item)))
      ).filter(x => x)) as SidebarModuleType[]
      this.sidebar.set(community, sidebar)
      this.emit('sidebarUpdate', new Map(this.sidebar))
      // clone this.sidebar map
      const sidebarClone = new Map(this.sidebar) as Map<string, (SidebarModuleType | string)[]>
      Array.from(sidebarClone.keys()).forEach(key => {
        sidebarClone.set(
          key,
          sidebarClone.get(key)?.map(x => {
            const module = x as SidebarModuleType
            if (module.id?.startsWith('@core.')) {
              return module.id
            }
            return module
          }) || []
        )
      })
      localforage.setItem('cachedSidebar', sidebarClone)
      this.cached = false
    } else {
      this.sidebar.set(community, [])
      this.emit('sidebarUpdate', this.sidebar)
      this.cached = false
    }
  }
  updateState(open: boolean) {
    this.emit('sidebarStateUpdate', open)
  }
}
