// LICENSE
//
//   This software is dual-licensed to the public domain and under the following
//   license: you are granted a perpetual, irrevocable license to copy, modify,
//   publish, and distribute this file as you see fit.
//
// VERSION
//   0.1.0  (2016-03-28)  Initial release
//
// AUTHOR
//   Forrest Smith
//
// CONTRIBUTORS
//   J�rgen Tjern� - async helper
//   Anurag Awasthi - updated to 0.2.0

const SEQUENTIAL_BONUS = 15 // bonus for adjacent matches
const SEPARATOR_BONUS = 30 // bonus if match occurs after a separator
const CAMEL_BONUS = 30 // bonus if match is uppercase and prev is lower
const FIRST_LETTER_BONUS = 15 // bonus if the first letter is matched

const LEADING_LETTER_PENALTY = -5 // penalty applied for every letter in str before the first match
const MAX_LEADING_LETTER_PENALTY = -15 // maximum penalty for leading letters
const UNMATCHED_LETTER_PENALTY = -1

/**
 * Returns true if each character in pattern is found sequentially within str
 */
export function fuzzyMatchSimple(pattern: string, str: string) {
  let patternIdx = 0
  let strIdx = 0
  const patternLength = pattern.length
  const strLength = str.length

  while (patternIdx != patternLength && strIdx != strLength) {
    const patternChar = pattern.charAt(patternIdx).toLowerCase()
    const strChar = str.charAt(strIdx).toLowerCase()
    if (patternChar == strChar) ++patternIdx
    ++strIdx
  }

  return patternLength != 0 && strLength != 0 && patternIdx == patternLength ? true : false
}

/**
 * Does a fuzzy search to find pattern inside a string.
 * @param {*} pattern string        pattern to search for
 * @param {*} str     string        string which is being searched
 * @returns [boolean, number]       a boolean which tells if pattern was
 *                                  found or not and a search score
 */
export function fuzzyMatch(pattern: string, str: string) {
  // Score consts
  const adjacency_bonus = 5 // bonus for adjacent matches
  const separator_bonus = 10 // bonus if match occurs after a separator
  const camel_bonus = 10 // bonus if match is uppercase and prev is lower
  const leading_letter_penalty = -3 // penalty applied for every letter in str before the first match
  const max_leading_letter_penalty = -9 // maximum penalty for leading letters
  const unmatched_letter_penalty = -1 // penalty for every letter that doesn't matter

  // Loop constiables
  let score = 0
  let patternIdx = 0
  const patternLength = pattern.length
  let strIdx = 0
  const strLength = str.length
  let prevMatched = false
  let prevLower = false
  let prevSeparator = true // true so if first letter match gets separator bonus

  // Use "best" matched letter if multiple string letters match the pattern
  let bestLetter = null
  let bestLower = null
  let bestLetterIdx = null
  let bestLetterScore = 0

  const matchedIndices = []

  // Loop over strings
  while (strIdx != strLength) {
    const patternChar = patternIdx != patternLength ? pattern.charAt(patternIdx) : null
    const strChar = str.charAt(strIdx)

    const patternLower = patternChar != null ? patternChar.toLowerCase() : null
    const strLower = strChar.toLowerCase()
    const strUpper = strChar.toUpperCase()

    const nextMatch = patternChar && patternLower == strLower
    const rematch = bestLetter && bestLower == strLower

    const advanced = nextMatch && bestLetter
    const patternRepeat = bestLetter && patternChar && bestLower == patternLower
    let formattedStr = ''
    if (advanced || patternRepeat) {
      score += bestLetterScore
      matchedIndices.push(bestLetterIdx)
      bestLetter = null
      bestLower = null
      bestLetterIdx = null
      bestLetterScore = 0
    }

    if (nextMatch || rematch) {
      let newScore = 0

      // Apply penalty for each letter before the first pattern match
      // Note: std::max because penalties are negative values. So max is smallest penalty.
      if (patternIdx == 0) {
        const penalty = Math.max(strIdx * leading_letter_penalty, max_leading_letter_penalty)
        score += penalty
      }

      // Apply bonus for consecutive bonuses
      if (prevMatched) newScore += adjacency_bonus

      // Apply bonus for matches after a separator
      if (prevSeparator) newScore += separator_bonus

      // Apply bonus across camel case boundaries. Includes "clever" isLetter check.
      if (prevLower && strChar == strUpper && strLower != strUpper) newScore += camel_bonus

      // Update patter index IFF the next pattern letter was matched
      if (nextMatch) ++patternIdx

      // Update best letter in str which may be for a "next" letter or a "rematch"
      if (newScore >= bestLetterScore) {
        // Apply penalty for now skipped letter
        if (bestLetter != null) score += unmatched_letter_penalty

        bestLetter = strChar
        bestLower = bestLetter.toLowerCase()
        bestLetterIdx = strIdx
        bestLetterScore = newScore
      }

      prevMatched = true
    } else {
      // Append unmatch characters
      formattedStr += strChar

      score += unmatched_letter_penalty
      prevMatched = false
    }

    // Includes "clever" isLetter check.
    prevLower = strChar == strLower && strLower != strUpper
    prevSeparator = strChar == '_' || strChar == ' '

    ++strIdx
  }

  // Apply score for last match
  if (bestLetter) {
    score += bestLetterScore
    matchedIndices.push(bestLetterIdx)
  }

  // Finish out formatted string after last pattern matched
  // Build formated string based on matched letters
  let formattedStr = ''
  let previousMatch = 0
  for (let i = 0; i < matchedIndices.length; ++i) {
    const idx = matchedIndices[i] ?? 0
    formattedStr += str.substring(previousMatch, idx)
    formattedStr += `<sp>${str.substring(idx, idx + 1)}<sp>`
    previousMatch = idx+1
  }
  formattedStr += str.substring(previousMatch)

  const matched = patternIdx == patternLength
  return [matched, score, formattedStr] as [boolean, number, string]
}

/**
 * Strictly optional utility to help make using fts_fuzzy_match easier for large data sets
 * Uses setTimeout to process matches before a maximum amount of time before sleeping
 *
 * To use:
 *  const asyncMatcher = new ftsFuzzyMatchAsync(fuzzyMatch, "fts", "ForrestTheWoods",
 *                                              function(results) { console.log(results); });
 *  asyncMatcher.start(); 
 */
export class FuzzyMatchAsync {
  ITEMS_PER_CHECK = 1000
  results = [] as [number, string, string][]
  max_ms_per_frame = 1000.0 / 30.0
  dataIndex = 0
  resumeTimeout = null as null | NodeJS.Timeout
  pattern: string
  dataSet: string[]
  onComplete: (results: [number, string, string][]) => void
  constructor(pattern: string, dataSet: string[], onComplete: (results: [number, string, string][]) => void) {
    this.pattern = pattern
    this.dataSet = dataSet
    this.onComplete = onComplete
  }
  step() {
    this.resumeTimeout && clearTimeout(this.resumeTimeout)
    this.resumeTimeout = null

    const stopTime = performance.now() + this.max_ms_per_frame

    for (; this.dataIndex < this.dataSet.length; ++this.dataIndex) {
      if (this.dataIndex % this.ITEMS_PER_CHECK == 0) {
        if (performance.now() > stopTime) {
          this.resumeTimeout = setTimeout(this.step, 1)
          return
        }
      }

      const str = this.dataSet[this.dataIndex]
      const result = fuzzyMatch(this.pattern, str)
      this.results.push([result[1], str, result[2]])
    }

    this.onComplete(this.results)
    return this.results
  }
  async start() {
    return await this.step()
  }
  cancel() {
    this.resumeTimeout && clearTimeout(this.resumeTimeout)
  }
  async flush() {
    this.max_ms_per_frame = Infinity
    this.step()
  }
}
