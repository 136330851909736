import { useEffect, useState } from 'react'
import { SelfUserClass } from '../../classes/SelfUserClass'
import { User } from '../../types/userTypes'

export const useCurrentUser = (cachable: boolean = true) => {
  const [user, setUser] = useState(undefined as User | null | undefined)
  useEffect(() => {
    const userClass = SelfUserClass.getInstance()
    const sUser = () => {
      setUser(JSON.parse(JSON.stringify(userClass.user)))
    }
    if (cachable || !userClass.cached) {
      sUser()
    }

    userClass.addListener('self-update', sUser)

    return () => {
      userClass.removeListener('self-update', sUser)
    }
  }, [cachable])
  return user
}
