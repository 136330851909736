import EventEmitter from 'events'
import localforage from 'localforage'
import { API_DOMAIN } from '../../constants'
import { getPersistentStore } from '../../PersistentStore'
import { Assignment, CourseStructure, UpcomingCourseEvent } from '../../types/courseTypes'
import { fetcher } from '../Fetcher'

const mergeMaterialMetaProps = (material: Assignment | undefined | null, newMaterial: Assignment) => {
  const publicMetaPropOld = material?.metaProps?.find(x => !x.personal)
  const publicMetaPropNew = newMaterial.metaProps?.find(x => !x.personal)
  const privateMetaPropOld = material?.metaProps?.find(x => x.personal)
  const privateMetaPropNew = newMaterial.metaProps?.find(x => x.personal)
  // console.log('mergeMaterialMetaProps', publicMetaPropOld, publicMetaPropNew, privateMetaPropOld, privateMetaPropNew,[
  //   ...(publicMetaPropOld && !publicMetaPropNew ? [publicMetaPropOld] : []),
  //   ...(publicMetaPropNew ? [publicMetaPropNew] : []),
  //   ...(privateMetaPropOld && !privateMetaPropNew ? [privateMetaPropOld] : []),
  //   ...(privateMetaPropNew ? [privateMetaPropNew] : []),
  // ])
  return [
    ...(publicMetaPropOld && !publicMetaPropNew ? [publicMetaPropOld] : []),
    ...(publicMetaPropNew ? [publicMetaPropNew] : []),
    ...(privateMetaPropOld && !privateMetaPropNew ? [privateMetaPropOld] : []),
    ...(privateMetaPropNew ? [privateMetaPropNew] : []),
  ]
}
const mergeMaterial = (material: Assignment | undefined | null, newMaterial: Assignment) =>
  ({
    ...material,
    ...newMaterial,
    metaProps: mergeMaterialMetaProps(material, newMaterial),
  } as Assignment)

export class CourseMaterialsDataClass extends EventEmitter {
  static _instanceMap = new Map<string, CourseMaterialsDataClass>()
  static getInstance(courseID: string, noInit?: boolean) {
    if (!CourseMaterialsDataClass._instanceMap.has(courseID)) {
      CourseMaterialsDataClass._instanceMap.set(courseID, new CourseMaterialsDataClass(courseID, noInit))
    }
    return CourseMaterialsDataClass._instanceMap.get(courseID)!
  }
  courseID: string
  materials = null as null | Map<string, Assignment>
  lastFetch = new Map<string, number>()
  overdue = null as null | Set<string>
  upcomingEvents = null as null | Map<string, UpcomingCourseEvent>
  courseStructure = null as null | CourseStructure
  private constructor(courseID: string, noInit?: boolean) {
    super()
    this.courseID = courseID
    this.init(noInit)
    this.setMaxListeners(1000)
  }

  async init(noFetch?: boolean) {
    this.readFromCache()
    if (noFetch) return
    this.getMaterials()
    this.getOverdueMaterials()
    this.getUpcomingEvents()
    this.getCourseStructure()
  }
  async writeToCache() {
    ;(await localforage).setItem(`course.${this.courseID}.materials`, this.materials)
    ;(await localforage).setItem(`course.${this.courseID}.overdue`, this.overdue)
    ;(await localforage).setItem(`course.${this.courseID}.upcomingEvents`, this.upcomingEvents)
    ;(await localforage).setItem(`course.${this.courseID}.courseStructure`, this.courseStructure)
  }
  async readFromCache() {
    await localforage.ready()
    let start = performance.now()
    await Promise.all(
      [
        this.readMaterialsFromCache(),
        this.readOverdueMaterialsFromCache(),
        this.readUpcomingEventsFromCache(),
        this.readCourseStructureFromCache(),
      ].map(x => x.then(() => console.log(`${performance.now() - start}ms`)))
    )
    console.log(`[CourseMaterialsDataClass] readFromCache: ${performance.now() - start}ms`)
    // this.readFromCache
  }
  async readMaterialsFromCache() {
    const materials = await (await localforage).getItem(`course.${this.courseID}.materials`)
    if (materials) {
      this.materials = materials as Map<string, Assignment>
      this.emit('materialUpdate', {
        materials: this.materials,
        cached: true,
      })
    }
  }
  async readOverdueMaterialsFromCache() {
    const overdue = await (await localforage).getItem(`course.${this.courseID}.overdue`)
    if (overdue) {
      this.overdue = overdue as Set<string>
      this.emit('overdueUpdate', {
        overdue: this.overdue,
        cached: true,
      })
    }
  }
  async readUpcomingEventsFromCache() {
    const upcomingEvents = await (await localforage).getItem(`course.${this.courseID}.upcomingEvents`)
    if (upcomingEvents) {
      this.upcomingEvents = upcomingEvents as Map<string, UpcomingCourseEvent>
      this.emit('upcomingEventsUpdate', {
        upcomingEvents: this.upcomingEvents,
        cached: true,
      })
    }
  }
  async readCourseStructureFromCache() {
    const courseStructure = await (await localforage).getItem(`course.${this.courseID}.courseStructure`)
    if (courseStructure) {
      this.courseStructure = courseStructure as CourseStructure
      this.emit('courseStructureUpdate', {
        courseStructure: this.courseStructure,
        cached: true,
      })
    }
  }

  async onMaterialUpdate(material: Assignment) {
    this.materials!.set(material.id, mergeMaterial(this.materials!.get(material.id), material))
    this.emit('materialUpdate', {
      material,
      cached: false,
    })
  }
  async getMaterials(cachable: boolean = true) {
    const response = await fetcher(`${API_DOMAIN}/courses/${this.courseID}/materials${!cachable ? `?fresh=true` : ''}`)
    const data = await response.json()
    const materials = data.materials as Assignment[]
    this.materials = this.materials ?? new Map()
    materials.map(mat => {
      this.materials!.set(mat.id, mergeMaterial(this.materials!.get(mat.id), mat))
      this.emit('materialUpdate', {
        material: mat,
        cached: cachable,
      })
    })
    this.writeToCache()
    return data.materials
  }
  async getMaterial(materialID: string, cachable: boolean = true, noCache: boolean = false) {
    const response = await fetcher(
      `${API_DOMAIN}/courses/${this.courseID}/material/${materialID}${
        !cachable ? `?fresh=true` : noCache ? `?nocache=true` : ''
      }`
    )
    const data = await response.json()
    const material = data.material as Assignment
    this.materials = this.materials ?? new Map()
    this.materials.set(material.id, mergeMaterial(this.materials.get(material.id), material))
    this.emit('materialUpdate', {
      material,
      cached: cachable,
    })
    this.writeToCache()
    return material
  }
  async getOverdueMaterials() {
    const response = await fetcher(`${API_DOMAIN}/courses/${this.courseID}/materials/overdue`)
    const data = await response.json()
    this.overdue = new Set(data.materials as string[])
    this.emit('overdueUpdate', {
      overdue: this.overdue,
      cached: false,
    })
    this.writeToCache()
    return data.materials as string[]
  }
  async getUpcomingEvents() {
    const response = await fetcher(`${API_DOMAIN}/courses/${this.courseID}/materials/upcoming`)
    const data = (await response.json()) as { success: boolean; events: UpcomingCourseEvent[] }
    this.upcomingEvents = this.upcomingEvents ?? new Map()
    data.events.map(event => {
      this.upcomingEvents!.set(event.id, event)
    })
    this.emit('upcomingEventsUpdate', {
      upcomingEvents: this.upcomingEvents,
      cached: false,
    })
    this.writeToCache()
    return data.events
  }
  async getCourseStructure(fresh: boolean = true) {
    const response = await fetcher(
      `${API_DOMAIN}/courses/${this.courseID}/materials/structure?${fresh ? 'fresh=true' : ''}`
    )
    const data =
      response.ok &&
      ((await response.json()) as {
        success: true
        cached?: boolean
        lastUpdated?: number
        structure: CourseStructure
      })
    if (data) {
      this.courseStructure = data.structure
      this.emit('courseStructureUpdate', {
        courseStructure: data.structure,
        cached: data.cached ?? false,
      })
      this.writeToCache()
    }
    return data && data.structure
  }
}
