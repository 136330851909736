import { Community } from '../../types/communityTypes'
import { Assignment, Course } from '../../types/courseTypes'
import { SearchMaterial, SearchType } from '../../types/quicksearch'

const CourseSearchParser = (course: Course, community?: Community) =>
  ({
    id: course.id,
    name: course.name,
    parent: community?.name,
    type: SearchType.Course,
    href: `/app/courses/${course.id}`,
    icon: course.icon,
  } as SearchMaterial)

const AssignmentSearchParser = (assignment: Assignment, course: Course) =>{
  if (!assignment.title) {
    return null
  }
  
  return ({
    id: `${course.id}-${assignment.id}`,
    name: assignment.title,
    parent: course.name,
    type: SearchType.Material,
    href: `/app/courses/${course.id}/materials/${assignment.id}`,
    icon: course.icon,
  } as SearchMaterial)}

export const SearchParsers = {
  CourseSearchParser,
  AssignmentSearchParser,
}
