import React from 'react'
export type NotifClick = () => void
export type Notif = {
  title: string
  message: string
  type: 'success' | 'error' | 'info' | 'warning'
  image?: string
  duration?: number
  onClick?: NotifClick
}
function checkNotificationPromise() {
  try {
    Notification.requestPermission().then()
  } catch (e) {
    return false
  }

  return true
}
class Notifier {
  listeners: Map<number, (notification: Notif) => any>
  backlog: Notif[] = []
  inc: number
  static self?: Notifier
  constructor() {
    this.listeners = new Map()
    this.inc = 0
    Notifier.self = this
  }
  onNotify(func: (notification: Notif) => any) {
    this.inc++
    this.listeners.set(this.inc, func)
    console.log('adding listener', this.inc)
    if (this.backlog.length > 0) {
      this.backlog.forEach(notif => {
        this.listeners.forEach(func => {
          func(notif)
        })
      })
      this.backlog = []
    }
    return this.inc
  }
  removeListener(id: number) {
    console.log('removing listener', id)
    this.listeners.delete(id)
  }
  notify(notif: Notif) {
    if (this.listeners.size === 0) {
      this.backlog.push(notif)
      return
    }
    if (this.backlog.length > 0) {
      this.backlog.forEach(notif => {
        this.listeners.forEach(func => {
          func(notif)
        })
      })
      this.backlog = []
    }
    this.listeners.forEach(listener => listener(notif))
  }
}
export const NotificationManager = new Notifier()
export const NotifierContext = React.createContext<Notifier>(NotificationManager)
export const NotifierProvider = NotifierContext.Provider
